import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountVerificationRequested: false,
    isCityAndCountryFilled: false,
    toggleScroll: true,
    drawer: true,
    barColor: "#492a67 0%, #582963  100%",
    barImage: require(`../assets/sidebar-1.jpg`),
    inquiryViewMode: true,
    routeLeaveAlert: false,
    onRouteLeaveAlertPage: false,
    inquiryVerificationStatus: {
      hasPermissions: false,
      verified: false,
      requested: false,
      requestable: false,
    },
    inquiryStatus: "active",
    accountManagers: [],
    loadingCard: null,
    reloadDropCard: null,
    updateInquiryInAppBar: false,
    updateCollectionInAppBar: {
      collectionNumber: null,
      collectionName: null,
    },
    updateStyleInAppBar: {
      styleName: null,
      styleNumber: null,
    },
    inquirySuppliersUpdated: false,
    bomRefreshToggle: false,
    collectionCounts: {},
  },
  mutations: {
    SET_ACCOUNT_VERIFICATION_REQUESTED(state, payload) {
      state.accountVerificationRequested = payload;
    },
    SET_CITY_AND_COUNTRY_IS_FILLED(state, payload) {
      state.isCityAndCountryFilled = payload;
    },
    SET_SCROLL_TOGGLE(state, payload) {
      state.toggleScroll = payload;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_INQUIRY_VIEW_MODE(state, payload) {
      state.inquiryViewMode = payload;
    },
    SET_ROUTE_LEAVE_ALERT(state, payload) {
      state.routeLeaveAlert = payload;
    },
    SET_INQUIRY_STATUS(state, payload) {
      state.inquiryStatus = payload;
    },
    SET_INQUIRY_VERIFICATION_STATUS(state, payload) {
      state.inquiryVerificationStatus = payload;
    },
    SET_ACCOUNT_MANAGER(state, payload) {
      state.accountManagers = payload;
    },
    SET_LOADING_CARD(state, payload) {
      state.loadingCard = payload;
    },
    SET_RELOAD_DROP_CARD(state, payload) {
      state.reloadDropCard = payload;
    },
    SET_UPDATE_INQUIRY_IN_APP_BAR(state, payload) {
      state.updateInquiryInAppBar = payload;
    },
    SET_UPDATE_INQUIRY_SUPPLIER(state, payload) {
      state.inquirySuppliersUpdated = payload;
    },
    SET_BOM_REFRESH_TOGGLE(state, payload) {
      state.bomRefreshToggle = payload;
    },
    SET_COLLECTION_COUNTS(state, payload) {
      state.collectionCounts = payload;
    },
    SET_UPDATE_COLLECTION_IN_APP_BAR(state, payload) {
      state.updateCollectionInAppBar = payload;
    },
    SET_UPDATE_STYLE_IN_APP_BAR(state, payload) {
      state.updateStyleInAppBar = payload;
    },
  },
  actions: {},
  modules: {},
});
